.tableHead {
  @apply bg-gray-100 px-2.5 py-[14px] text-left 2xl:px-3 2xl:py-4;
}

.tableBody {
  @apply border-b border-gray-200 px-2.5 py-[25px] 2xl:px-3 2xl:py-7;
}

/* use with char &#8942; */
.kebabButton {
  background-color: transparent;
  border: 0;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1.2rem;
}

.kebabButton:hover {
  background-color: #ebe9f2;
}

.kebabButton:active {
  background-color: #c9c7d0;
}

.dataDropdown {
  padding: 1px;
  background-color: #fff;
  border: 1px black solid;
  text-transform: none;
  text-align: left;
  width: 95%;
}

.sortFilterResetButton {
  display: inline-block;
  font-size: 75%;
  margin-left: 25px;
  padding: 3px 5px;
  border-radius: 3px;
  cursor: pointer;
}

.sortFilterResetButton:hover {
  color: #fff;
  background-color: #9880ee;
}

.sortFilterResetButton:active {
  color: #fff;
  background-color: #6755a8;
}

.sortFilterHelpButton {
  display: inline-block;
  padding: .2rem .5rem;
  color: white;
  background-color: #0070b3;
  font-size: 90%;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
}

.sortFilterHelpButton:hover {
  color: #fff;
  background-color: #0060a2;
}

.sortFilterHelpButton:active {
  color: #fff;
  background-color: #003088;
}

.accessioningTH {
  border-bottom: 1px solid lightgray;
  padding: 2px 4px;
  text-align: center;
}

.accessioningTR {
  border-bottom: 1px solid lightgray;
}

.pagingButton {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;
}
.pagingButton:hover {
  border-color: #646cff;
  color: #fff;
  background-color: #cacaca;
  font-weight: bold;
}
.pagingButton:focus,
.pagingButton:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

.accessioning-modal {
  /*max-width: 1200px;*/
  width: 80%;
  min-width: 800px;
}

@media (max-width: 800px) {
  .accessioning-modal {
    min-width: 100%;
  }
}

@media (min-width: 1000px) {
  .accessioning-modal {
    max-width: 1200px;
  }
}

.accessioning-modal table {
  border-collapse: separate;
  border-spacing: 0;
  border: 2px solid #e5e8ee;
  border-radius: 6px;
}

.accessioning-modal th {
  text-align: center;
  background-color: #e2e7f1;
}

.accessioning-error-alert-modal {
  width: 400px;
  color: red;
  text-align: center;
}

.accessioning-success-alert-modal {
  width: 400px;
  text-align: center;
}

.accessioning-choice-modal {
  background-color: inherit;
}

.accessioning-choice-modal:hover {
  background-color: #f8e8f8;
  cursor: pointer;
}