@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
@charset "UTF-8";
body {
  font-size: 0.86rem;
  padding: 56px 0 0 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #000000;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.link-button {
  background: none;
  border: none;
  cursor: pointer;
}

.wrap {
  position: relative;
  padding-left: 240px;
}

strong {
  font-weight: 400;
}

h1 {
  font-size: 1.6rem;
}

a, .link-button {
  color: #1c96e0;
}

.btn {
  padding: 6px 12px;
  cursor: pointer;
  font-size: 0.86rem;
}
.btn.btn-sm {
  padding: 3px 8px;
}
.btn.btn-link {
  text-decoration: none;
}
.btn.btn-link.btn-sm {
  padding: 3px 0;
}
.btn.btn-link:hover {
  box-shadow: none;
}

.card {
  box-shadow: 0 2px 15px rgba(84, 96, 103, 0.25);
  border: none;
  margin-bottom: 30px;
}
.card .card-title {
  font-weight: 400;
}
.card .card-subtitle {
  margin-top: 20px;
}
.card .card-subtitle span {
  margin: 0 15px;
}
.card .card-subtitle span:first-child {
  margin-left: 0;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 260px;
}
.sidebar .logo {
  display: block;
  margin: 10px;
}
.sidebar .search {
  border: 1px solid #fff;
  background: #fff;
  display: block;
  padding: 4px 9px;
  width: 100%;
  border-radius: 3px;
  outline: none;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
}
.sidebar .search:focus {
  border-color: #b0e7f9;
}
.sidebar ul {
  display: block;
  margin-top: 20px;
}
.sidebar ul li {
  padding-left: 30px;
}
.sidebar ul li a, .sidebar ul li .link-button {
  color: #0e2a39;
  font-size: 0.9rem;
}
.sidebar ul li a:hover, .sidebar ul li .link-button:hover {
  color: #1f88a8;
}
.wrap {
  padding-left: 0;
}

.loading {
  display: block;
  width: 60px;
  margin: 60px auto;
}
.loading.finished {
  display: none;
}

.hidden {
  display: none;
}

.run-header {
  background: #0b3c5d;
  color: #d9f4fb;
}
.run-header h5.card-title {
  font-weight: 300;
}
.run-header h5.card-title span {
  font-weight: 400;
}
.run-header h6.card-subtitle {
  font-weight: 300;
}

.items-table-wrapper {
  margin-left: -20px;
  margin-right: -20px;
}

.items-table {
  display: table;
  width: 100%;
}
.items-table .table-row {
  display: table-row;
  color: #132c4c;
  text-decoration: none;
}
.items-table .table-row.table-header {
  text-transform: uppercase;
  font-weight: 400;
}
.items-table .table-row.table-header > div {
  background: #ecf4fb;
  border-bottom: none;
}
.items-table .table-row.table-header.fixed > div {
  background: #d53270;
}
.items-table .table-row:not(.table-header):hover {
  background: #f4fbfd;
}

.sample-table svg {
  display: inline-block;
  margin-top: 4px;
}

.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.dropdown-menu {
  z-index: 9999 !important;
}

.run-type {
  border: 1px solid #000;
  padding: 4px 0;
  border-radius: 99px;
  display: inline-block;
  width: 60px;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
}
.run-type.miseq {
  border-color: #ffb7d1;
  color: #ffb7d1;
}
.run-type.s5 {
  border-color: #af93f4;
  color: #af93f4;
}

.text-muted {
  color: #8f8f8f !important;
}

small {
  font-weight: 300;
}

.events-modal {
  max-width: 100%;
  margin-left: 30px;
  margin-right: 30px;
}

.row-clickable {
  background: #ffffff;
  border: 1px solid #e6eff4;
  padding: 10px 0;
  cursor: pointer;
  margin-bottom: 4px;
  transition: 0.18s;
}
.row-clickable .delete {
  opacity: 0;
  transition: 0.18s;
  font-size: 18px;
  line-height: 18px;
}
.row-clickable:hover {
  border-color: #81b5e0;
  background: #f6faff;
}
.row-clickable:hover .delete {
  opacity: 1;
}
.row-clickable p {
  margin: 0;
}
.row-clickable .row-title {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.page {
  padding-top: 20px;
}
.page header {
  margin: -20px -20px 40px -20px;
  padding: 40px 20px;
  background: #1a3c5b;
  color: #fff;
}
.page header h1, .page header h2, .page header h3, .page header h4 {
  margin: 0;
}
.page header h1 {
  font-size: 28px;
}
.page header h4 {
  font-size: 34px;
  font-weight: 300;
}
.page section {
  margin-bottom: 40px;
}
.page section h4 {
  font-size: 18px;
  margin-bottom: 20px;
}

span.required {
  color: #e05162;
}

.modal-body {
  position: relative;
}
.modal-body .sending {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.54);
  z-index: 999;
  display: none;
}
.modal-body .sending.active {
  display: block;
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */
.rdt {
  position: relative;
}
.rdt input[type="text"] {
  height: calc(1.5em + 0.5rem + 2px);
  font-size: .875rem;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}

.rdtOpen .rdtPicker {
  display: block;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}

.rdtPicker th.rdtSwitch {
  width: 100px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  /* Firefox */
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  /* Firefox */
  /* Internet Explorer/Edge */
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: #eee;
}

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.sample-list-row {
  display: block;
  text-decoration: none !important;
  color: #000;
}

.rdw-editor-main {
  border: 1px solid #ddd;
  padding-left: 15px;
  padding-right: 15px;
}

.sample-group-container {
  position: relative;
  padding-left: 240px;
  padding-right: 340px;
}

h5 {
  margin: 0;
  font-size: 18px;
}

p {
  margin-bottom: 4px;
}

strong {
  font-weight: normal;
}

.sidebar-menu {
  position: fixed;
  width: 240px;
  left: 0;
  bottom: 0;
  top: 56px;
  padding: 15px;
  background: #F7FAFB;
  z-index: 999;
  overflow: auto;
  border-right: 1px solid #ddd;
}

.run-rows {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 50%;
  overflow-y: scroll;
  border-top: 1px solid #e7e7e7;
  padding-bottom: 20px;
}
.run-rows .run-row {
  padding: 6px 11px;
  border-left: 4px solid transparent;
  cursor: pointer;
}
.run-rows .run-row:hover {
  border-left-color: #cceafd;
  background: #f4fbfd;
}
.run-rows .run-row.selected {
  border-left-color: #f9bb25;
  color: #f99916;
}

.machine-rows {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  overflow-y: scroll;
  border-top: 1px solid #e7e7e7;
  padding-bottom: 20px;
}
.machine-rows .machine-row {
  padding: 6px 11px;
  border-left: 4px solid transparent;
  cursor: pointer;
}
.machine-rows .machine-row:hover {
  border-left-color: #cceafd;
  background: #f4fbfd;
}
.machine-rows .machine-row.selected {
  border-left-color: #f9bb25;
  color: #f99916;
}

.sidebar-detail-view {
  position: fixed;
  right: 0;
  width: 340px;
  bottom: 0;
  top: 56px;
  padding: 15px;
  background: #fff;
  z-index: 999;
  border-left: 1px solid #ddd;
  overflow: auto;
}

.sample-selector {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.23);
  display: none;
}
.sample-selector.open {
  display: block;
  left: 50%;
}
.sample-selector header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  border-bottom: 3px solid #f4f4f4;
  padding: 20px;
  background: #ffffff;
  height: 230px;
}

.sample-receiver {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: none;
}
.sample-receiver.open {
  display: block;
}

.empty-samples-list {
  height: 300px;
  border: 3px dashed #000;
}

.sample-list-header {
  margin-bottom: 40px;
}

.sample-row {
  padding: 6px 6px;
  position: relative;
  transition: border 0.12s, background-color 0.3s;
  cursor: pointer;
  border-bottom: 1px solid #f4f4f4;
  margin-left: -15px;
  margin-right: -15px;
}
.sample-row.selected {
  background: #fffded;
}
.sample-row .sample-row-handle {
  position: absolute;
  height: 48px;
  width: 48px;
  right: 0;
  top: 0;
  background: #000;
  z-index: 9999;
}
.sample-row .sample-date {
  padding-right: 12px;
}
.sample-row .check, .sample-row .sex {
  padding: 0 4px;
}
.sample-row .sample-preview-plot {
  width: 120px;
  height: 48px;
  overflow: hidden;
  display: block;
  margin-right: 10px;
}
.sample-row .sample-handle {
  opacity: 0;
  position: relative;
  display: none;
}
.sample-row .sample-handle .sample-handle-image {
  display: block;
  width: 8px;
}
.sample-row .sample-handle .sample-handle-dragging {
  background: #000;
  color: #fff;
  position: absolute;
  top: -3px;
  left: -6px;
  padding: 7px;
  border-radius: 99px;
  font-size: 11px;
  opacity: 0;
}
.sample-row .sample-handle.dragging {
  opacity: 1;
}
.sample-row .sample-handle.dragging .sample-handle-image {
  display: none;
}
.sample-row .sample-handle.dragging .sample-handle-dragging {
  display: block;
  margin-left: 6px;
}
.sample-row:hover {
  background: #f4fbfd;
}
.sample-row:hover .sample-handle {
  opacity: 1;
}

.run-header {
  margin: 0 -15px 0 -15px;
  padding: 15px;
  background: #093352;
}
.run-header p {
  margin-bottom: 2px;
  font-size: 12px;
}

.run-controls {
  margin-top: 20px;
}

.report-form-submitted {
  text-align: center;
  padding: 120px 0;
  font-size: 34px;
  color: #1ec093;
}

.pagination {
  border: 1px solid #cceafd;
  background: #ffffff;
  padding: 0 4px;
  border-radius: 99px;
  margin-bottom: 0;
}
.pagination li {
  padding: 2px 4px;
}
.pagination li a, .pagination li .link-button {
  cursor: pointer;
  text-decoration: none;
  outline: none;
}
.pagination li.disabled a, .pagination li.disabled .link-button {
  color: #dddddd;
}
.pagination li.active a, .pagination li.active .link-button {
  color: #f9bb25;
}

.gp-badge.count-badge {
  border-color: #00a699;
  color: #00a699;
}

h5 .gp-badge {
  font-size: 0.86rem;
  padding: 2px 8px;
  background: #ffffff;
  border: 1px solid #1c96e0;
  border-radius: 99px;
  color: #1c96e0;
}

.drag-over-bg {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.12);
}
.drag-over-bg.dragging {
  background: rgba(0, 0, 0, 0.45);
}

.dragging-display {
  background: #1a3c5b;
  border: 1px solid #1a3c5b;
  padding: 4px 12px;
  margin-top: -20px;
  color: #fff;
  font-size: 12px;
  border-radius: 99px;
}

.modal-dialog.sample-modal {
  max-width: 100%;
  margin-left: 30px;
  margin-right: 30px;
}
.check-all {
  padding-left: 10px;
}

.samples-header {
  padding: 15px 0;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.sample-header-fixed {
  position: relative;
  z-index: 999;
}
.sample-header-fixed .samples-header {
  background: #f9f9f9;
  margin: 0 -15px -15px -15px;
  padding: 15px;
  border-left: 1px solid #f0f5fb;
  border-right: 1px solid #f0f5fb;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.12);
}

.group-header {
  padding: 20px;
  background: #1a3c5b;
  color: #000;
  margin: -15px -20px 0 -20px;
}
.group-header .group-title {
  padding: 60px 20px;
}
.group-header .group-title p {
  margin: 0;
}

.search {
  position: relative;
}
.search span {
  position: absolute;
  right: 4px;
  top: 2px;
  cursor: pointer;
  font-size: 20px;
}
.search span:hover {
  color: #e3961b;
}

.run-plot {
  font-family: "Roboto Mono", monospace;
}
.run-plot text {
  font-size: 11px !important;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.spinning {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 600ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 600ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
}

.m-tree {
  margin-bottom: 12px;
}

.m-node.placeholder {
  border: 1px dashed #1385e5;
}
.m-node .inner {
  color: #000;
  font-size: 14px;
}
.m-node .inner .collapse {
  display: block;
  height: 29px;
  width: 20px;
}
.m-node .inner .collapse:before {
  font-family: FontAwesome;
  display: block;
  content: "";
  height: 29px;
  line-height: 29px;
}
.m-node .inner .collapse.caret-down:before {
  content: "";
}
.m-node .inner .collapse.caret-right:before {
  content: "";
}
.m-node .node {
  display: inline-block;
  width: 100%;
  padding: 4px 5px;
}
.m-node .node.is-active {
  color: #1c96e0;
}
.m-node .node .group-row {
  display: block;
}
.m-node .node .group-row.dragging-over {
  background-color: #d5f3ff;
}

.links-list {
  list-style: none;
  padding: 0;
}

.sidebar-title {
  margin: 0;
}

.copy-number-table {
  width: 100%;
}
.copy-number-table tr td {
  padding: 6px 0;
  font-size: 10px;
  text-align: center;
}
.copy-number-table tr td:nth-child(even) {
  background: #f0fcff;
}

.sample-details .row .col:first-child {
  color: #7d7d7d;
}

hr.full-width {
  display: block;
  margin-left: -15px;
  margin-right: -15px;
}

.form-error {
  font-size: 80%;
}

.card-header {
  background: transparent;
  border-bottom: 2px solid #f4f4f4;
  padding: 12px 20px;
}
.card-header h6 {
  text-transform: none;
  line-height: 30px;
}

.modal-lg {
  max-width: 860px !important;
}

.file-row {
  margin-left: -20px;
  margin-right: -20px;
  border-bottom: 1px solid #f4f4f4;
  padding: 6px 5px;
  cursor: pointer;
}
.file-row:first-child {
  border-top: 1px solid #f4f4f4;
}
.file-row:hover {
  background: #f8f8f8;
}

.saliva-sample-modal .modal-dialog {
  max-width: 600px !important;
}

.show-auth {
  color: #d199ea;
}
.show-auth input {
  background: rgba(209, 153, 234, 0.26);
}

.table td, .table th {
  border-color: #e7e7e7 !important;
}
.table thead th {
  font-weight: 500;
}
.with-table {
  margin: -20px -20px 0 -20px;
}
.with-table thead tr th {
  border-top: none;
  border-bottom-width: 1px;
  background: #f8f8f8;
}
.with-table thead tr th:first-child {
  padding-left: 20px;
}
.with-table thead tr th:last-child {
  padding-right: 20px;
}
.with-table tbody tr td:first-child {
  padding-left: 20px;
}
.with-table tbody tr td:last-child {
  padding-right: 20px;
}

.btn-primary {
  background: #9761d5;
  border-color: #9761d5;
}
.btn-primary:hover {
  background: #7D50B0;
  border-color: #7D50B0;
}

.card .card-body.with-link a, .card .card-body.with-link .link-button {
  text-decoration: none !important;
  color: #000000;
}
.card .card-body.with-link a h2, .card .card-body.with-link .link-button h2 {
  color: #165476;
  padding: 30px 0;
}

.case-tabs .nav-link {
  display: inline-block;
  text-transform: uppercase;
  color: #1c96e0;
}
.case-tabs .nav-link.active {
  color: #165476;
  border-bottom: 2px solid #165476;
}

.accession-tabs .nav-link {
  display: inline-block;
  color: #777777;
}
.accession-tabs .nav-link.active {
  color: #76709a;
  font-weight: bold;
  border-bottom: 2px solid #76709a;
}


.error-message {
  color: #dc3545;
  margin: 0;
  padding: 0;
}

/*# sourceMappingURL=main.css.map */

body {
  font-size: 0.86rem;
  padding: 20px 0 120px 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #132c4c;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  background: #F1F4F6;
}

strong {
  font-weight: 500;
}

h6 {
  text-transform: uppercase;
}

.text-muted {
  color: #b1b1b1 !important;
}

.wrap {
  position: relative;
  padding-left: 280px;
  padding-right: 20px;
}
@media screen and (max-width: 480px) {
  .wrap {
    padding-left: 20px;
    padding-top: 20px !important;
    overflow-x: scroll;
  }
}

.case-status-canceled .card {
  border-top: 2px solid #efbd00;
}

.table-container {
  margin: -21px -20px 0 -20px;
}
.table-container .table thead th {
  background: #f4f4f4;
}
.table-container .table td {
  vertical-align: middle !important;
  cursor: pointer;
}

.loading {
  width: 60px;
  overflow: hidden;
  margin: 60px auto;
}

.dropdown-menu {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  border-color: #ffffff;
  margin-top: -20px;
}

.btn {
  text-transform: uppercase;
  border-radius: 3px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 0.86rem;
  font-weight: 500;
}
.btn:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
}
.btn.btn-sm {
  padding-left: 10px;
  padding-right: 10px;
}
.btn.btn-success {
  background: #47b472;
  border-color: transparent;
}

.btn-row {
  margin: 20px -15px 0 -15px;
  border-top: 1px solid #eeeeee;
  padding-top: 15px;
}

.btn-group .btn {
  border-radius: 3px;
}

.input-group-append .btn {
  border-left: none;
  margin-left: 1px;
}

.modal-header {
  background: #f0f8fd;
  position: relative;
}

.modal-dialog.danger .modal-header {
  background: #fde3ec;
  color: #9c1743;
}

.modal-title {
  font-weight: 400;
}

.modal-close {
  display: block;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -10px;
  cursor: pointer;
  font-size: 20px;
  line-height: 20px;
  height: 20px;
}
.modal-close:hover {
  color: #e12e4a;
}

.card-brand {
  height: 30px;
  display: block;
  position: absolute;
  right: 21px;
  top: 50%;
  margin-top: -15px;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 220px;
  z-index: 1049;
}
.sidebar .sidebar-container {
  position: relative;
  height: 100%;
  min-height: 500px;
}
.sidebar .search {
  border: 1px solid #fff;
  background: #fff;
  display: block;
  padding: 7px 14px;
  width: 100%;
  border-radius: 3px;
  outline: none;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
}
.sidebar .search:focus {
  border-color: #bbe4f6;
}
.sidebar ul {
  display: block;
  margin-top: 20px;
  width: 220px;
}
.sidebar ul.bottom {
  position: absolute;
  bottom: 40px;
}
.sidebar ul li {
  padding-left: 30px;
}
.sidebar ul li a, .sidebar ul li .link-button {
  display: block;
  position: relative;
  color: #132c4c;
  font-size: 0.92rem;
  font-weight: 500;
}
.sidebar ul li a.active, .sidebar ul li .link-button.active {
  color: #2fb8f5;
}
.sidebar ul li a.active:after, .sidebar ul li .link-button.active:after {
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
}
.sidebar ul li a:hover, .sidebar ul li .link-button:hover {
  color: #1f88a8;
}
.sidebar .sidebar-icon {
  color: #a19ed6;
}
.sidebar .sidebar .nav-item:nth-child(1) .sidebar-icon {
  color: #7ed6c2;
}
.sidebar .sidebar .nav-item:nth-child(2) .sidebar-icon {
  color: #82b9d6;
}
.sidebar .sidebar .nav-item:nth-child(3) .sidebar-icon {
  color: #c792d6;
}
.sidebar .alert {
  border: 4px solid black !important;
}
.sidebar a.logo {
  display: block;
  margin: 20px 0 30px 30px;
}
.sidebar a.logo img {
  display: block;
  width: 100%;
}
.sidebar a.create {
  display: inline-block;
  border-radius: 3px;
  margin: 20px 0 10px 0;
  background: #fcfcfc;
  color: #132c4c;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
}

.btn-create {
  display: inline-block;
  border-radius: 3px;
  border: none;
  padding: 6px 16px !important;
  margin: 20px 0 10px 0;
  background: #fcfcfc;
  color: #132c4c;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
}

.copy-number-table {
  width: 100%;
}
.copy-number-table tr td {
  padding: 6px 0;
  font-size: 10px;
  text-align: center;
}
.copy-number-table tr td:nth-child(even) {
  background: #dbf2f7;
}

.content-with-sidebar {
  position: relative;
  padding-right: 380px;
  padding-top: 20px;
}

.sidebar-detail {
  position: fixed;
  width: 360px;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
}

.sample-list-row {
  color: #132c4c;
  text-decoration: none !important;
}

.sample-row-title {
  font-size: 1.01rem;
}

.sample-row {
  padding: 6px 6px;
  position: relative;
  transition: border 0.12s, background-color 0.3s;
  cursor: pointer;
  border-bottom: 1px solid #f4f4f4;
  margin-left: -15px;
  margin-right: -15px;
}
.sample-row.selected {
  background: #fffded;
}
.sample-row .sample-row-handle {
  position: absolute;
  height: 48px;
  width: 48px;
  right: 0;
  top: 0;
  background: #000;
  z-index: 9999;
}
.sample-row .sample-date {
  padding-right: 12px;
}
.sample-row .check, .sample-row .sex {
  padding: 0 4px;
}
.sample-row .sample-preview-plot {
  width: 120px;
  height: 48px;
  overflow: hidden;
  display: block;
  margin-right: 10px;
}
.sample-row .sample-handle {
  opacity: 0;
  position: relative;
  display: none;
}
.sample-row .sample-handle .sample-handle-image {
  display: block;
  width: 8px;
}
.sample-row .sample-handle .sample-handle-dragging {
  background: #000;
  color: #fff;
  position: absolute;
  top: -3px;
  left: -6px;
  padding: 7px;
  border-radius: 99px;
  font-size: 11px;
  opacity: 0;
}
.sample-row .sample-handle.dragging {
  opacity: 1;
}
.sample-row .sample-handle.dragging .sample-handle-image {
  display: none;
}
.sample-row .sample-handle.dragging .sample-handle-dragging {
  display: block;
  margin-left: 6px;
}
.sample-row:hover {
  background: #f4fbfd;
}
.sample-row:hover .sample-handle {
  opacity: 1;
}

.card {
  margin-bottom: 30px;
  border: none;
}
.card .card-title {
  font-weight: 400;
}
.card .card-subtitle {
  margin-top: 20px;
}
.card .card-subtitle span {
  margin: 0 15px;
}
.card .card-subtitle span:first-child {
  margin-left: 0;
}

.row-clickable {
  background: #ffffff;
  border: 1px solid #e6eff4;
  padding: 10px 0;
  cursor: pointer;
  margin-bottom: 4px;
  transition: 0.18s;
}
.row-clickable .delete {
  opacity: 0;
  transition: 0.18s;
  font-size: 18px;
  line-height: 18px;
}
.row-clickable:hover {
  border-color: #81b5e0;
  background: #f6faff;
}
.row-clickable:hover .delete {
  opacity: 1;
}
.row-clickable p {
  margin: 0;
}
.row-clickable .row-title {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.items-table-wrapper {
  margin-left: -20px;
  margin-right: -20px;
}

.items-table {
  display: table;
  width: 100%;
  border-top: 1px solid #deeaf0;
}
.items-table .table-row {
  display: table-row;
  color: #132c4c;
  text-decoration: none;
}
.items-table .table-row:not(.table-header):first-child {
  border-top: 1px solid #deeaf0;
}
.items-table .table-row > div {
  display: table-cell;
  vertical-align: middle;
  padding: 8px 16px 8px 16px;
  border-bottom: 1px solid #deeaf0;
}
.items-table .table-row > div:first-child {
  padding-left: 20px;
}
.items-table .table-row > div:last-child {
  padding-right: 20px;
}
.items-table .table-row .col {
  padding: 8px 16px 8px 16px;
}
.items-table .table-row .col:first-child {
  padding-left: 20px;
}
.items-table .table-row .col:last-child {
  padding-right: 20px;
}
.items-table .table-row.table-header {
  text-transform: uppercase;
  font-weight: 400;
}
.items-table .table-row.table-header > div {
  background: #fbfbfb;
  border-bottom: none;
}
.items-table .table-row.table-header.fixed > div {
  background: #d53270;
}
.items-table .table-row:not(.table-header):hover {
  background: #f4fbfd;
}

.card-body {
  position: relative;
}

.remove-btn {
  background: transparent;
  border: none;
  color: #e03261;
}
.remove-btn.batch-remove-btn {
  position: absolute;
  top: 4px;
  right: 4px;
}

.modal-content {
  border: none;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.24);
}

.no-samples {
  padding: 60px 0 80px 0;
  text-align: center;
  color: #c5d8ef;
  font-size: 24px;
}

.menu-icon {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.sidebar-detail-sample .row {
  margin-bottom: 6px;
}

.case-empty-results {
  margin: 0 auto 60px auto;
  padding: 100px 0 40px 0;
  width: 480px;
  text-align: center;
  color: #bbd8e2;
}
.case-empty-results h4 {
  font-size: 28px;
  line-height: 54px;
  font-weight: 300;
}

.document-table tr td {
  border: none;
  vertical-align: middle;
}

.document-image-preview {
  width: 64px;
  height: 64px;
  background: #f4f4f4;
  background-size: cover;
  display: inline-block;
}

.collapse-handle {
  color: #626262;
  font-size: 18px;
  padding: 12px 15px;
  cursor: pointer;
  border-top: 1px solid #f4f4f4;
  margin: 0 -15px;
}
.collapse-handle:first-of-type {
  border-top: 1px solid transparent;
}
.collapse-handle:hover {
  color: #0e2a39;
}
.collapse-handle.active {
  color: #1c96e0;
}
.collapse-handle.invalid {
  color: #e12e4a;
}

.ion-fw {
  display: inline-block;
  width: 1rem;
  text-align: left;
}

.StripeElement--focus {
  border-color: #80BDFF;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-header {
  margin-bottom: 40px;
}

.list-item {
  background: #ffffff;
  border-radius: 4px;
  display: block;
  margin-bottom: 12px;
  padding: 24px;
  text-decoration: none !important;
  color: #132c4c !important;
  transition: 0.3s;
  border-left: 2px solid #9ab2ba;
}
.list-item.warning {
  border-left: 2px solid #ffbf00;
}
.list-item.danger {
  border-left: 2px solid #ff345c;
}
.list-item.ready {
  border-left: 2px solid #47b472;
}
.list-item.shipped {
  border-left: 2px solid #a077db;
}
.list-item.delivered {
  border-left: 2px solid #3bbedb;
}
.list-item:hover {
  box-shadow: 0 6px 22px rgba(0, 0, 0, 0.12);
}
.list-item .header {
  font-size: 1.4rem;
  font-weight: 400;
}

.text-muted {
  color: #7B8B90 !important;
}

.text-blue {
  color: #3bbedb;
}

.text-purple {
  color: #a077db;
}

.text-success {
  color: #47b472 !important;
}

.display {
  color: #788b91;
}
.display h4 {
  font-weight: 300;
  font-size: 1.98rem;
  margin: 0;
}

.list-item .display h4 {
  font-size: 1.6rem;
}

.list-item-image {
  width: 80px;
  height: 80px;
  border-radius: 99px;
  background: #f4f4f4;
}

.card-bottom {
  margin-top: 15px;
}

.card.inverted {
  background: #04253c;
  color: #ffffff;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#606c88+0,3f4c6b+100;Grey+3D+%232 */
  background: #606c88;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #606c88 0%, #3f4c6b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#606c88", endColorstr="#3f4c6b",GradientType=0 );
  /* IE6-9 */
}

span.error {
  color: #e12e4a;
}

.card-table {
  margin: -20px;
}
.card-table table {
  width: 100%;
}
.card-table table th {
  border-top: 1px solid #f4f4f4;
  padding: 10px;
}
.card-table table th:first-child {
  padding-left: 20px;
}
.card-table table th:last-child {
  padding-right: 20px;
}
.card-table table tr.clickable-row td > a, .card-table table tr.clickable-row td > span, .card-table table tr.clickable-row td > .link-button {
  padding: 10px;
}
.card-table table tr.clickable-row td:first-child > a, .card-table table tr.clickable-row td:first-child > span, .card-table table tr.clickable-row td:first-child > .link-button {
  padding-left: 20px;
}
.card-table table tr.clickable-row td:last-child > a, .card-table table tr.clickable-row td:last-child > span, .card-table table tr.clickable-row td:last-child > .link-button {
  padding-right: 20px;
}
.card-table table th {
  border-top: none;
  background: #f7f7f7;
  text-transform: uppercase;
  font-weight: 500;
}
.card-table table tr:not(.clickable-row) td {
  padding: 10px;
  border-bottom: 1px solid #f4f4f4;
}
.card-table table tr:not(.clickable-row) td:first-child {
  padding-left: 20px;
}
.card-table table tr:not(.clickable-row):last-child td {
  border: none;
}

.editor-wrapper {
  background: #ffffff;
}
.editor-wrapper .editor {
  min-height: 600px;
  border-radius: 4px;
}
.issue {
  padding: 6px;
  background: #f4f4f4;
  margin: 10px 0 10px 0;
  display: block;
}
.issue span {
  color: #999999;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.56);
  z-index: 99;
}

.bp3-overlay {
  z-index: 9999999 !important;
}

/*# sourceMappingURL=provider.css.map */

.loading-overlay {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.74); }
  .loading-overlay h4 {
    position: fixed;
    display: block;
    font-size: 42px;
    line-height: 42px;
    height: 42px;
    top: 50%;
    margin-top: 20px;
    text-align: center;
    width: 100%;
    font-weight: 300; }
  .loading-overlay .loading-indicator {
    position: fixed;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -100px; }

/*# sourceMappingURL=style.css.map */



/*# sourceMappingURL=main.css.map */

.g-badge {
  background: #f4f4f4;
  display: inline-block;
  font-size: 0.86rem;
  padding: 8px 12px;
  margin: 2px 2px;
  border-radius: 99px;
  color: #000000;
  font-weight: 500; }
  .g-badge.green {
    background: #d9ffd2;
    color: #1e717d; }
  .g-badge.orange {
    background: #fff7e8;
    color: #d87d54; }
  .g-badge.default {
    background: #ddeaf6;
    color: #082b3b; }
  .g-badge.muted {
    background: #d2dde9;
    color: #68879e; }
  .g-badge.lg {
    font-size: 1.1rem;
    padding: 8px 24px; }

/*# sourceMappingURL=style.css.map */

.g-alert {
  background: #fff2f7;
  color: #a94b5b;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 0.92rem;
  border-radius: 4px; }

/*# sourceMappingURL=style.css.map */

.image-preview {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #ececec;
  display: block;
  cursor: pointer; }

.img-upload-thumb {
  position: relative; }
  .img-upload-thumb .btn {
    display: block;
    transition: 0.3s; }
  .img-upload-thumb:not(.empty) .btn {
    opacity: 0; }
  .img-upload-thumb:not(.empty):hover .btn {
    opacity: 1; }
  .img-upload-thumb .plus-svg {
    display: block;
    width: 100%; }
  .img-upload-thumb.empty {
    transition: 0.3s;
    cursor: pointer; }
    .img-upload-thumb.empty .image-preview {
      background-size: 40% 40%; }
    .img-upload-thumb.empty .img-upload-btn {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .img-upload-thumb.empty:hover {
      box-shadow: 0 12px 36px rgba(0, 0, 0, 0.06); }

.img-upload-btn {
  position: absolute;
  bottom: 5px;
  display: block;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 5px; }
  .img-upload-btn .btn {
    display: block; }

.img-delete-btn {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px; }
  .img-delete-btn .btn {
    display: block; }

.img-upload-thumb .shader {
  background: rgba(0, 0, 0, 0.56);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  transition: 0.3s;
  cursor: pointer; }
.img-upload-thumb .file-controls {
  position: absolute;
  cursor: pointer;
  font-size: 14px;
  line-height: 36px;
  width: 36px;
  height: 36px;
  border-radius: 99px;
  top: 6px;
  left: 6px;
  background: #ffffff;
  text-align: center;
  z-index: 2;
  display: none; }
  .img-upload-thumb .file-controls.delete {
    margin-left: 40px; }
.img-upload-thumb:hover .shader {
  opacity: 1; }
.img-upload-thumb:hover .file-controls {
  display: block; }
.img-upload-thumb.empty .shader {
  display: none; }
.img-upload-thumb.empty .file-controls {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  background: transparent; }

/*# sourceMappingURL=style.css.map */

.gplot-container {
  position: relative;
  z-index: 1; }
  .gplot-container .timelapse {
    position: absolute;
    width: 270px;
    height: 270px;
    top: 50%;
    left: 50%;
    border-radius: 50% 50%;
    margin-top: -137.5px;
    margin-left: -135px;
    overflow: hidden;
    z-index: 1; }
    .gplot-container .timelapse img {
      display: block;
      width: 100%;
      height: 100%; }
  .gplot-container .gplot {
    position: relative;
    z-index: 2; }
    .gplot-container .gplot .chr {
      transition: 0.3s; }
      .gplot-container .gplot .chr .bg {
        fill: #1ec093;
        transition: 0.12s; }
        .gplot-container .gplot .chr .bg.hovered {
          fill: #189774; }
      .gplot-container .gplot .chr.empty .bg {
        fill: #f4f4f4; }
      .gplot-container .gplot .chr .cn-line {
        stroke: #bad6cd; }
      .gplot-container .gplot .chr.high-risk .bg {
        fill: #f53471; }
      .gplot-container .gplot .chr.high-risk .cn-line {
        stroke: #ffdce5; }
      .gplot-container .gplot .chr.hovered .bg {
        fill: #87dcaf; }
      .gplot-container .gplot .chr.hovered.high-risk .bg {
        fill: #ff7491; }

/*# sourceMappingURL=style.css.map */

.paginator {
  position: relative; }
  .paginator.skeleton {
    width: 200px;
    overflow: hidden; }
    .paginator.skeleton:after {
      content: '';
      display: block;
      background: #ffffff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999; }
  .paginator .paginator-btn {
    display: inline-block;
    padding: 1px 7px;
    border: 1px solid transparent;
    border-radius: 99px;
    margin-right: 2px; }
    .paginator .paginator-btn.active {
      color: #ffffff;
      background: #165476;
      border: 1px solid #165476; }
  .paginator .paginator-total {
    padding: 3px 12px; }

/*# sourceMappingURL=main.css.map */



/*# sourceMappingURL=main.css.map */

.provider-select {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.provider-select select {
  border: none !important;
  background: transparent;
  display: block;
  outline: none !important;
  max-width: 200px;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 0 0 15px;
  position: relative;
  z-index: 2;
  text-overflow: ellipsis;
  cursor: pointer;
}
.provider-select .toggle {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}

.select-clinic {
  height: 240px;
  overflow: auto;
}

/*# sourceMappingURL=main.css.map */

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #154b64;
  color: #ffffff;
  padding: 8px 20px;
  z-index: 99999; }
  .mobile-menu .switch {
    text-align: center;
    font-size: 24px; }
  .mobile-menu .menu {
    overflow: hidden;
    height: 0; }
    .mobile-menu .menu.open {
      height: auto;
      margin: 20px 0; }
    .mobile-menu .menu .nav .nav-item {
      display: block;
      width: 100%;
      color: #ffffff; }
      .mobile-menu .menu .nav .nav-item a {
        color: #ffffff;
        display: block; }

/*# sourceMappingURL=mobile_menu.css.map */

.table-link table {
  width: 100%; }
  .table-link table tr td {
    padding: 0; }
    .table-link table tr td a {
      display: block;
      padding: 6px 10px;
      text-decoration: none !important; }
    .table-link table tr td:first-child a {
      padding-left: 0; }
    .table-link table tr td:last-child a {
      padding-right: 0; }
  .table-link table tr:hover td, .table-link table tr:hover th {
    background: #d9e6f1; }
.table-link.table-link-stretch {
  margin-left: -20px;
  margin-right: -20px; }
  .table-link.table-link-stretch table tr td:first-child a {
    padding-left: 20px; }
  .table-link.table-link-stretch table tr td:last-child a {
    padding-right: 20px; }

/*# sourceMappingURL=main.css.map */

.list-item .header {
  margin-bottom: 6px; }
.list-item .gauge {
  text-align: center; }
  .list-item .gauge h4 {
    font-weight: 300;
    font-size: 28px;
    margin: 0; }

/*# sourceMappingURL=main.css.map */

.card-table {
  margin: -20px; }
  .card-table table {
    width: 100%; }
    .card-table table th {
      border-top: 1px solid #f4f4f4;
      padding: 10px; }
      .card-table table th:first-child {
        padding-left: 20px; }
      .card-table table th:last-child {
        padding-right: 20px; }
    .card-table table tr.clickable-row td > a, .card-table table tr.clickable-row td > span {
      padding: 10px; }
    .card-table table tr.clickable-row td:first-child > a, .card-table table tr.clickable-row td:first-child > span {
      padding-left: 20px; }
    .card-table table tr.clickable-row td:last-child > a, .card-table table tr.clickable-row td:last-child > span {
      padding-right: 20px; }
    .card-table table th {
      border-top: none;
      background: #f7f7f7;
      text-transform: uppercase;
      font-weight: 500; }

/*# sourceMappingURL=main.css.map */

tr.clickable-row:hover td {
  background: #fafafa; }
tr.clickable-row td {
  padding: 0;
  border-bottom: 1px solid #f4f4f4; }
  tr.clickable-row td > a, tr.clickable-row td > span {
    display: block;
    text-decoration: none;
    color: #132c4c;
    cursor: pointer; }
tr.clickable-row:last-child td {
  border-bottom: none; }
  tr.clickable-row:last-child td:first-child {
    border-bottom-left-radius: 4px; }
  tr.clickable-row:last-child td:last-child {
    border-bottom-right-radius: 4px; }

/*# sourceMappingURL=main.css.map */

.toast-popup-container {
  position: fixed;
  z-index: 99999;
  top: 20px;
  width: 320px;
  left: 50%;
  margin-left: -160px; }
  .toast-popup-container .toast-popup {
    position: relative;
    padding: 12px 24px 12px 12px;
    background: rgba(255, 255, 255, 0.78);
    border-radius: 3px;
    margin-bottom: 20px;
    transition: 0.5s;
    transition-delay: 0.5s; }
    .toast-popup-container .toast-popup .toast-popup-close {
      position: absolute;
      top: 4px;
      right: 6px;
      font-size: 12px;
      line-height: 12px;
      cursor: pointer; }
    .toast-popup-container .toast-popup.danger {
      background: rgba(255, 28, 74, 0.78);
      color: #ffffff; }
    .toast-popup-container .toast-popup.warning {
      background: rgba(255, 236, 98, 0.78);
      color: #721c10; }
    .toast-popup-container .toast-popup.success {
      background: rgba(88, 241, 137, 0.78);
      color: #09382f; }

/*# sourceMappingURL=main.css.map */

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.wrap {
  padding-top: 60px; }

.top-bar {
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0;
  left: 0;
  background: #F1F4F6;
  z-index: 999; }
  .top-bar .row {
    height: 60px; }
  .top-bar a {
    text-decoration: none !important;
    color: #132c4c; }
    .top-bar a:hover {
      color: #1f88a8; }

.img-upload-thumb {
  margin-bottom: 30px; }
  .img-upload-thumb .image-preview {
    background-size: cover; }

.card {
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07) !important; }

.page-title {
  font-weight: 400;
  font-size: 1.7rem;
  margin: 0; }

.page-sup-title {
  font-weight: 300;
  font-size: 1.45rem; }

.page-subtitle {
  font-weight: 400;
  font-size: 1.1rem;
  text-transform: none; }

/*# sourceMappingURL=main.css.map */

.placeholder {
  width: 100%; }
@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 2%; }
  50% {
    background-position: 100% 99%; }
  100% {
    background-position: 0% 2%; } }
@keyframes AnimationName {
  0% {
    background-position: 0% 2%; }
  50% {
    background-position: 100% 99%; }
  100% {
    background-position: 0% 2%; } }
  .placeholder .bg-animated {
    background: linear-gradient(314deg, #f4f9fc, #fcfcfc, #f4f9fc, #fcfcfc, #f4f9fc, #fcfcfc, #f4f9fc, #fcfcfc);
    background-size: 300% 300%;
    -webkit-animation: AnimationName 3s ease infinite;
    animation: AnimationName 3s ease infinite; }
  .placeholder .bg-animated-darker {
    background: linear-gradient(314deg, #f1f6fd, #d7e6ed);
    background-size: 300% 300%;
    -webkit-animation: AnimationName 3s ease infinite;
    animation: AnimationName 3s ease infinite; }
  .placeholder .element {
    height: 40px;
    margin-bottom: 15px; }
    .placeholder .element.line-1 {
      height: 40px; }
    .placeholder .element.line-2 {
      height: 95px; }
    .placeholder .element.line-3 {
      height: 155px; }
  .placeholder .g-plot {
    width: 240px;
    height: 240px;
    border-radius: 999px; }
  .placeholder.placeholder-table .row {
    height: 60px;
    margin: 6px 0; }
  .placeholder.placeholder-sample-detail .title {
    width: 120px;
    height: 28px;
    margin-bottom: 8px; }

/*# sourceMappingURL=style.css.map */

.kit-order .bp3-spinner-animation svg {
    height: 38px;
    width: 38px;
}
.patient-select {
    margin-bottom: 5px;
}
.patient-select .invalid-feedback {
    margin-top: 0;
    color: green;
}
.d-block {
    display: block;
}
.tableHead {
  @apply bg-gray-100 px-2.5 py-[14px] text-left 2xl:px-3 2xl:py-4;
}

.tableBody {
  @apply border-b border-gray-200 px-2.5 py-[25px] 2xl:px-3 2xl:py-7;
}

/* use with char &#8942; */
.kebabButton {
  background-color: transparent;
  border: 0;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1.2rem;
}

.kebabButton:hover {
  background-color: #ebe9f2;
}

.kebabButton:active {
  background-color: #c9c7d0;
}

.dataDropdown {
  padding: 1px;
  background-color: #fff;
  border: 1px black solid;
  text-transform: none;
  text-align: left;
  width: 95%;
}

.sortFilterResetButton {
  display: inline-block;
  font-size: 75%;
  margin-left: 25px;
  padding: 3px 5px;
  border-radius: 3px;
  cursor: pointer;
}

.sortFilterResetButton:hover {
  color: #fff;
  background-color: #9880ee;
}

.sortFilterResetButton:active {
  color: #fff;
  background-color: #6755a8;
}

.sortFilterHelpButton {
  display: inline-block;
  padding: .2rem .5rem;
  color: white;
  background-color: #0070b3;
  font-size: 90%;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
}

.sortFilterHelpButton:hover {
  color: #fff;
  background-color: #0060a2;
}

.sortFilterHelpButton:active {
  color: #fff;
  background-color: #003088;
}

.accessioningTH {
  border-bottom: 1px solid lightgray;
  padding: 2px 4px;
  text-align: center;
}

.accessioningTR {
  border-bottom: 1px solid lightgray;
}

.pagingButton {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;
}
.pagingButton:hover {
  border-color: #646cff;
  color: #fff;
  background-color: #cacaca;
  font-weight: bold;
}
.pagingButton:focus,
.pagingButton:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

.accessioning-modal {
  /*max-width: 1200px;*/
  width: 80%;
  min-width: 800px;
}

@media (max-width: 800px) {
  .accessioning-modal {
    min-width: 100%;
  }
}

@media (min-width: 1000px) {
  .accessioning-modal {
    max-width: 1200px;
  }
}

.accessioning-modal table {
  border-collapse: separate;
  border-spacing: 0;
  border: 2px solid #e5e8ee;
  border-radius: 6px;
}

.accessioning-modal th {
  text-align: center;
  background-color: #e2e7f1;
}

.accessioning-error-alert-modal {
  width: 400px;
  color: red;
  text-align: center;
}

.accessioning-success-alert-modal {
  width: 400px;
  text-align: center;
}

.accessioning-choice-modal {
  background-color: inherit;
}

.accessioning-choice-modal:hover {
  background-color: #f8e8f8;
  cursor: pointer;
}
.request-page .logo {
  display: block;
  width: 400px;
  margin: 0 auto 60px auto; }
.request-page .header {
  margin-top: -20px;
  background: #183968;
  padding-top: 60px;
  padding-bottom: 60px;
  color: #ffffff; }
.request-page .provider-request-success {
  padding: 120px 0 120px 0;
  text-align: center; }
.request-page .login-link {
  color: #feb90b;
  text-decoration: none !important;
  font-weight: bold; }
  .request-page .login-link:hover {
    color: #fe631f; }

/*# sourceMappingURL=main.css.map */



/*# sourceMappingURL=fdh.css.map */

.image-preview {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #ececec;
  display: block;
  cursor: pointer; }

.img-upload-thumb {
  position: relative; }
  .img-upload-thumb .btn {
    display: block;
    transition: 0.3s; }
  .img-upload-thumb:not(.empty) .btn {
    opacity: 0; }
  .img-upload-thumb:not(.empty):hover .btn {
    opacity: 1; }
  .img-upload-thumb .plus-svg {
    display: block;
    width: 100%; }
  .img-upload-thumb.empty {
    transition: 0.3s;
    cursor: pointer; }
    .img-upload-thumb.empty .image-preview {
      background-size: 40% 40%; }
    .img-upload-thumb.empty .img-upload-btn {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .img-upload-thumb.empty:hover {
      box-shadow: 0 12px 36px rgba(0, 0, 0, 0.06); }

.img-upload-btn {
  position: absolute;
  bottom: 5px;
  display: block;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 5px; }
  .img-upload-btn .btn {
    display: block; }

.img-delete-btn {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px; }
  .img-delete-btn .btn {
    display: block; }

.img-upload-thumb .shader {
  background: rgba(0, 0, 0, 0.56);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  transition: 0.3s;
  cursor: pointer; }
.img-upload-thumb .file-controls {
  position: absolute;
  cursor: pointer;
  font-size: 14px;
  line-height: 36px;
  width: 36px;
  height: 36px;
  border-radius: 99px;
  top: 6px;
  left: 6px;
  background: #ffffff;
  text-align: center;
  z-index: 2;
  display: none; }
  .img-upload-thumb .file-controls.delete {
    margin-left: 40px; }
.img-upload-thumb:hover .shader {
  opacity: 1; }
.img-upload-thumb:hover .file-controls {
  display: block; }
.img-upload-thumb.empty .shader {
  display: none; }
.img-upload-thumb.empty .file-controls {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  background: transparent; }

/*# sourceMappingURL=style.css.map */

.case-status-indicator {
  font-size: 1rem;
  font-weight: 500; }

/*# sourceMappingURL=style.css.map */

.error-text {
    height: 20px;
}
.multi-select {
    width: 200px;
}
.recipients-form {
    padding-left: 100px;
    padding-right: 200px;
}
.height-options-2 {
    height: 160px;
}
.height-options-3 {
    height: 190px;
}
.height-options-4 {
    height: 230px;
}
.height-options-5 {
    height: 260px;
}
.th-width-90 {
    width: 90px
}
.th-width-135 {
    width: 135px
}
.generateOrBlock {
    display: flex;
    align-items: center
}
.blockReport {
    margin-left: 10%;
    margin-bottom: 0
}
.dob label {
  margin: 0;
  font-size: 12px; }
.dob > .row {
  margin: 0 -4px; }
  .dob > .row > div {
    padding: 0 4px; }

/*# sourceMappingURL=dob.css.map */

.new-case-modal.bp3-dialog {
    background-color: #fff;
}
.new-case-modal .bp3-dialog-header {
    background: #f0f8fd;
    padding: 1rem;
}

.new-case-modal .bp3-heading {
    font-weight: 400;
}

.panel-table td {
  border: none;
  padding: 2px 4px; }
  .panel-table td:first-child {
    padding-left: 0 !important; }
  .panel-table td:last-child {
    padding-right: 0 !important; }
  .panel-table td label.bp3-control.bp3-checkbox {
    margin: 0; }
.panel-table tr.risk-high td {
  color: #efbd00; }

/*# sourceMappingURL=panel_view.css.map */

.bp3-label {
  display: block;
  width: 120px; }

/*# sourceMappingURL=form.css.map */

.mutation-table-title {
  margin-left: -20px;
  margin-right: -20px;
  padding: 4px 20px;
  background: #f1edff;
  color: #7d4da7;
  font-weight: bold; }

.mutation-table {
  margin-left: -20px;
  margin-right: -20px; }
  .mutation-table .bp3-html-table thead td, .mutation-table .bp3-html-table thead th {
    vertical-align: bottom;
    background: #faf5ff; }
  .mutation-table .bp3-html-table tbody td {
    vertical-align: middle; }
    .mutation-table .bp3-html-table tbody td:first-child {
      padding-left: 10px; }
  .mutation-table .bp3-html-table td:first-child, .mutation-table .bp3-html-table th:first-child {
    padding-left: 20px; }
  .mutation-table .bp3-html-table td:last-child, .mutation-table .bp3-html-table th:last-child {
    padding-right: 20px; }

.add-gene-btn {
  padding: 0 10px 0 10px; }

/*# sourceMappingURL=main.css.map */

.note-item {
  background: #f6f7f9;
  padding: 12px;
  margin: 20px 0;
  border-radius: 12px; }
  .note-item:first-child {
    margin-top: 0; }
  .note-item .note-meta > p {
    display: inline-block;
    margin: 0 12px 0 0;
    font-size: 10px;
    color: #888888; }
  .note-item .note-meta .note-delete a {
    color: rgba(255, 0, 51, 0.89); }
  .note-item .note-meta .note-delete, .note-item .note-meta .note-edit {
    visibility: hidden; }
  .note-item:hover .note-meta .note-delete, .note-item:hover .note-meta .note-edit {
    visibility: visible; }

/*# sourceMappingURL=notes.css.map */

.card-loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 999; }

/*# sourceMappingURL=main.css.map */



/*# sourceMappingURL=main.css.map */

